<template>
  <TransitionRoot
    as="template"
    :show="open"
  >
    <Dialog
      as="div"
      class="relative z-10"
      @close="close"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black-600 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h2"
                    class="mb-3 font-quicksand text-lg font-semibold text-black-900"
                  >
                    {{ title }}
                  </DialogTitle>
                </div>
              </div>
              <div class="mt-5 flex justify-center gap-x-2 sm:mt-6">
                <button
                  type="button"
                  class="btn btn-secondary flex items-center gap-3 font-semibold"
                  @click="close"
                >
                  Verder winkelen
                </button>

                <NuxtLink
                  class="btn btn-tertiary flex items-center gap-3 font-semibold"
                  to="/winkelwagen"
                >
                  Bestellen
                  <UiIcon
                    name="arrow-right"
                    class="h-5 w-5"
                  />
                </NuxtLink>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';

defineProps<{
  title: string
  open: boolean
}>();

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update:open', value: boolean): void
}>();

function close() {
  emit('close');
  emit('update:open', false);
}

defineExpose({
  close,
});
</script>
